import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private http: HttpClient) {}

  url = `${environment.api}/v1/statistics`;

  getZeloReport(zeloId: string) {
    return this.http.get<{ report: string }>(
      `${this.url}/reports/zelo/${zeloId}`,
      {
        observe: 'response'
      }
    );
  }

  getOrganizationReport() {
    return this.http.get<{ report: string }>(
      `${this.url}/reports/organization`,
      {
        observe: 'response'
      }
    );
  }
}
