import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import {
  map,
  catchError,
  concatMap,
  exhaustMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  noop,
  oops,
  reduceZeloSummaryFn,
  ofTypeHuzza,
  ofTypeBrief,
  ofTypeOops,
  ofTypeMuster,
  ofTypeFetchAudienceTemplates,
  ofTypeSaveAudienceTemplate,
  ofTypeFetchZeloSummary,
  huzza,
  ofTypeSaveAttrZelo,
  ofTypeMusterEveryoneElse
} from '../actions/zelo.next.actions';
import * as ZeloNextActions from '../actions/zelo.next.actions';

import { ZeloService } from '../services/zelo.service';
import { AudienceService } from '../services/audience.service';
import { AttrZelo } from 'src/types';
import { ZeloUtilityService } from '../services/zelo.utility.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from 'src/app/organization.service';
import { SegmentService } from 'src/app/shared/services/segment.service';
import { Router } from '@angular/router';
import { SessionActivityService } from '../../core/services/session-activity.service';
import { Store } from '@ngrx/store';
import { getSelectedZeloSavingStatus } from '../reducers';

@Injectable({ providedIn: 'root' })
export class ZeloNextEffects {
  constructor(
    private actions$: Actions,
    private zeloService: ZeloService,
    private audienceService: AudienceService,
    private organizationService: OrganizationService,
    private app: ZeloUtilityService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private segment: SegmentService,
    private router: Router,
    private sessionActivity: SessionActivityService,
    private store: Store
  ) {}

  huzza$ = createEffect(
    () =>
      this.actions$.pipe(
        ofTypeHuzza(),
        tap((props) => this.app.huzza(props.i18n, props.substitutions))
      ),
    { dispatch: false }
  );

  brief$ = createEffect(
    () =>
      this.actions$.pipe(
        ofTypeBrief(),
        tap((props) => this.app.brief(props.i18n, props.substitutions))
      ),
    { dispatch: false }
  );

  oops$ = createEffect(
    () =>
      this.actions$.pipe(
        ofTypeOops(),
        tap((props) => this.app.oops(props.i18n, props.substitutions))
      ),
    { dispatch: false }
  );

  muster$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeMuster(),
      concatMap(({ station }) =>
        this.zeloService.muster(station).pipe(
          map((muster) => ZeloNextActions.musterSuccess({ muster, station })),
          catchError(oops())
        )
      )
    )
  );

  musterEveryoneElse$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeMusterEveryoneElse(),
      concatMap(({ station }) =>
        this.zeloService.muster(station).pipe(
          map((muster) => ZeloNextActions.musterSuccess({ muster, station })),
          catchError(oops())
        )
      )
    )
  );

  fetchAudienceTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeFetchAudienceTemplates(),
      exhaustMap(({ key }) =>
        this.audienceService.getAudienceTemplates(key).pipe(
          map((templates) =>
            ZeloNextActions.fetchAudienceTemplatesSuccess({
              templates,
              key
            })
          )
        )
      )
    )
  );

  saveAttrZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeSaveAttrZelo(),
      concatMap(({ attrZelo, silent }) =>
        this.audienceService.saveAttrZelo(attrZelo).pipe(
          map((attrZelo) =>
            ZeloNextActions.saveAttrZeloSuccess({ attrZelo, silent })
          ),
          catchError(oops())
        )
      )
    )
  );

  saveAttrZeloSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.saveAttrZeloSuccess),
        tap((action) => action.silent || this.app.huzza(action.type))
      ),
    { dispatch: false }
  );

  saveAudienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeSaveAudienceTemplate(),
      concatMap(({ template, deleteHeadRevision, deletePermanently }) =>
        this.audienceService
          .saveAudienceTemplate(template, deleteHeadRevision, deletePermanently)
          .pipe(
            map((result) =>
              deletePermanently
                ? ZeloNextActions.deleteAudienceTemplateSuccess(result)
                : ZeloNextActions.saveAudienceTemplateSuccess(result)
            ),
            catchError(oops())
          )
      )
    )
  );

  saveAudienceTemplateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.saveAudienceTemplateSuccess),
        tap((action) => this.app.huzza(action.type))
      ),
    { dispatch: false }
  );

  deleteAudienceTemplateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.deleteAudienceTemplateSuccess),
        tap((action) => this.app.huzza(action.type))
      ),
    { dispatch: false }
  );

  fetchZeloSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofTypeFetchZeloSummary(),
      exhaustMap(({ ids }) => {
        return this.zeloService
          .fetchZeloSummary(ids)
          .pipe(map(reduceZeloSummaryFn), catchError(oops()));
      })
    )
  );
  selectZeloById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.selectZeloById),
      concatMap(({ zeloId }) => {
        return this.zeloService.getZeloById(zeloId).pipe(
          map((zelo) => {
            return ZeloNextActions.selectZeloByIdSuccess({ zelo });
          }),
          catchError(() => of(ZeloNextActions.selectZeloByIdFailure()))
        );
      })
    )
  );

  selectZeloByIdSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.selectZeloByIdSuccess),
        tap((action) => {
          if (
            (this.router.url.endsWith(action.zelo.id) ||
              this.router.url.endsWith('settings') ||
              this.router.url.endsWith('setup') ||
              this.router.url.endsWith('send-to-others')) &&
            action.zelo.isSent
          ) {
            this.router.navigate(
              [`/zelo/${action.zelo.id}`, 'result', 'time'],
              { replaceUrl: true }
            );
          }
        })
      ),
    { dispatch: false }
  );

  selectZeloByIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.selectZeloByIdFailure),
        tap((action) => {
          this.app.oops(
            action.type,
            {},
            this.translate.instant('toasts.notFound')
          );
          this.router.navigate(['/zelo']);
        })
      ),
    { dispatch: false }
  );

  fetchOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.fetchOrg),
      exhaustMap(() => this.organizationService.fetchOrg()),
      map((org) => ZeloNextActions.fetchOrgSuccess({ org })),
      catchError((error) => of(ZeloNextActions.fetchOrgFailure(error)))
    )
  );

  fetchOrgFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.fetchOrgFailure),
        tap((action) => this.app.oops(action.type))
      ),
    { dispatch: false }
  );

  upsertChannelMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.upsertZeloChannelMessages),
      tap(() => {
        this.sessionActivity.triggerActiveAction();
      }),
      concatMap(({ channelMessages }) =>
        this.zeloService.upsertChannelMessages(channelMessages).pipe(
          map(() =>
            ZeloNextActions.upsertZeloChannelMessagesSuccess({
              channelMessages
            })
          ),
          catchError((error) =>
            of(ZeloNextActions.upsertZeloChannelMessagesFailure(error))
          )
        )
      )
    )
  );

  setZeloParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloParent),
      tap(({ id, parent }) => {
        this.segment.track('Folder Edited', {
          zeloId: id,
          folder: parent
        });
      }),
      concatMap(({ id, parent }) =>
        this.zeloService
          .setZeloParent(id, parent)
          .pipe(map(noop), catchError(oops()))
      )
    )
  );

  setZeloArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloArchived),
      tap(({ id, archived }) => {
        archived
          ? this.segment.track('Message Archived', {
              zeloId: id
            })
          : this.segment.track('Message De-Archived', {
              zeloId: id
            });
      }),
      concatMap(({ id, archived }) =>
        this.zeloService
          .setZeloArchived(id, archived)
          .pipe(
            map(
              huzza(
                archived
                  ? ZeloNextActions.setZeloArchived.type
                  : ZeloNextActions.setZeloRestored.type
              )
            ),
            catchError(oops())
          )
      )
    )
  );

  setZeloTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloTitle),
      concatMap(({ id, title }) =>
        this.zeloService
          .setZeloTitle(id, title)
          .pipe(map(noop), catchError(oops()))
      )
    )
  );

  setZeloChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloChannel),
      tap(({ station, channel }) => {
        this.segment.track('Channel Selected', {
          zeloId: (station.audience as AttrZelo).zeloId,
          channel: channel || 'Let Zelo Decide'
        });
      }),
      concatMap(({ station, channel }) => {
        const zeloId = (station.audience as AttrZelo).zeloId;

        return concat(
          this.zeloService
            .setZeloChannel(zeloId, channel)
            .pipe(map(noop), catchError(oops())),
          this.zeloService.muster(station).pipe(
            map((muster) => ZeloNextActions.musterSuccess({ muster, station })),
            catchError(oops())
          )
        );
      })
    )
  );

  setZeloSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloSchedule),
      tap(({ id, scheduleType }) => {
        this.segment.track('Scheduling Edited', {
          zeloId: id,
          type: scheduleType ? scheduleType : 'Manual'
        });
      }),
      concatMap(
        ({
          id,
          scheduleType,
          startAt,
          startAtTimezone,
          finishBy,
          finishByTimezone
        }) =>
          this.zeloService
            .setZeloSchedule(
              id,
              scheduleType,
              startAt,
              startAtTimezone,
              finishBy,
              finishByTimezone
            )
            .pipe(map(noop), catchError(oops()))
      )
    )
  );

  setZeloCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloCategories),
      tap(({ id }) => {
        this.segment.track('Categories Edited', {
          zeloId: id
        });
      }),
      concatMap(({ id, categories }) =>
        this.zeloService
          .setZeloCategories(id, categories)
          .pipe(map(noop), catchError(oops()))
      )
    )
  );

  setZeloMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloMode),
      tap(({ id, mode }) => {
        this.segment.track('MessageMode Updated', {
          zeloId: id,
          mode: mode
        });
      }),
      concatMap(({ id, mode }) =>
        this.zeloService.setZeloMode(id, mode).pipe(
          map((payload) => ZeloNextActions.setZeloModeSuccess(payload)),
          catchError(oops())
        )
      )
    )
  );

  setZeloSenderId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloSenderId),
      tap(({ id, senderId }) => {
        this.segment.track('Sender Updated', {
          zeloId: id,
          hasSender: senderId ? true : false
        });
      }),
      concatMap(({ id, senderId }) =>
        this.zeloService
          .setZeloSenderId(id, senderId)
          .pipe(map(noop), catchError(oops()))
      )
    )
  );

  setZeloSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloSteps),
      concatMap(({ id, steps }) =>
        this.zeloService.setZeloSteps(id, steps).pipe(
          map((result) => ZeloNextActions.setZeloStepsSuccess(result)),
          catchError(oops())
        )
      )
    )
  );

  setZeloSignup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setZeloSignup),
      tap(({ id, signup }) => {
        signup
          ? this.segment.track('Share Turned On', {
              zeloId: id
            })
          : this.segment.track('Share Turned Off', {
              zeloId: id
            });
      }),
      concatMap((payload) =>
        this.zeloService
          .setZeloSignup(payload)
          .pipe(map(noop), catchError(oops()))
      )
    )
  );

  deleteRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.deleteRecipients),
      concatMap(({ zeloId, recipientIds }) =>
        this.zeloService.deleteRecipients(zeloId, recipientIds).pipe(
          map(() =>
            ZeloNextActions.deleteRecipientsSuccess({ data: undefined })
          ),
          catchError(oops())
        )
      )
    )
  );

  deleteRecipientsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloNextActions.deleteRecipientsSuccess),
        tap((action) => this.app.huzza(action.type))
      ),
    { dispatch: false }
  );

  sendZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.sendZelo),
      exhaustMap(({ zelo }) => {
        const draftJob = zelo.distributorJobs.find(
          (j) => j.jobType === 'draft'
        );

        return this.zeloService
          .sendZelo(
            zelo.id,
            draftJob.startAt,
            draftJob.startAtTimezone,
            draftJob.finishBy,
            draftJob.finishByTimezone
          )
          .pipe(
            map((sentZelo) =>
              ZeloNextActions.sendZeloSuccess({ zelo: sentZelo })
            ),
            catchError((error) => of(ZeloNextActions.sendZeloFailure(error)))
          );
      })
    )
  );

  sendZeloSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.sendZeloSuccess),
      tap(({ zelo }) => {
        this.segment.track('Zelo Sent', {
          zeloId: zelo.id,
          scheduleType: zelo.scheduleType ? zelo.scheduleType : 'Manual',
          mode: zelo.mode
        });
      }),
      map(({ zelo }) => {
        const key = 'composer.shared.emptyState.sendZelo.description';
        const i18n = this.translate.instant(key);
        const options = { toastClass: 'ngx-toastr zelo-toast-success' };
        this.toastr.show(i18n, 'Success', options);
        this.router.navigate(['/zelo', zelo.id, 'result', 'time']);
        return ZeloNextActions.selectZeloById({ zeloId: zelo.id });
      }),
      catchError(oops())
    )
  );

  setBulkZeloParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setBulkZeloParent),
      concatMap(({ zeloIds, parent }) =>
        this.zeloService.setBulkZeloParent(zeloIds, parent)
      ),
      tap((zeloData) => {
        let message = zeloData[0].parent
          ? `Selected Zelos have been moved to ${zeloData[0].parent}`
          : `Selected Zelos have been removed from the folder`;
        this.toastr.show('', message, {
          toastClass: 'zelo-toast zelo-toast-success'
        });
        this.segment.track('Bulk Action Folders');
      }),
      map(noop),
      catchError(oops())
    )
  );

  setBulkZeloArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setBulkZeloArchive),
      concatMap(({ zeloIds, archived }) =>
        this.zeloService.setBulkZeloArchive(zeloIds, archived).pipe(
          map((data) => {
            let message =
              zeloIds.length === data.length
                ? `Selected Zelos have been archived`
                : `Selected Zelos have been archived, however, Zelos in sending or scheduled status are not archived`;
            this.toastr.show('', message, {
              toastClass: 'zelo-toast zelo-toast-success'
            });
            if (data.length === 0) {
              data = zeloIds.map((id) => {
                return { id, is_archived: false };
              });
            }
            this.segment.track('Bulk Action Archive');
            return ZeloNextActions.setBulkZeloArchiveSuccess({ data });
          }),
          catchError(oops())
        )
      )
    )
  );

  setBulkZeloDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setBulkZeloDelete),
      concatMap(({ zeloIds }) =>
        this.zeloService.setBulkZeloDelete(zeloIds).pipe(
          map((data) => {
            let message =
              zeloIds.length === data.length
                ? `Selected Zelos have been deleted`
                : `Selected Zelos have been deleted, however, Zelos in sending or scheduled status are not deleted`;
            this.toastr.show('', message, {
              toastClass: 'zelo-toast zelo-toast-success'
            });
            if (data.length === 0) {
              data = zeloIds.map((id) => {
                return { id, is_deleted: false };
              });
            }
            this.segment.track('Bulk Action Delete');
            return ZeloNextActions.setBulkZeloDeleteSuccess({ data });
          }),
          catchError(oops())
        )
      )
    )
  );

  setBulkZeloCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setBulkZeloCategories),
      concatMap(({ zeloIds, categories }) =>
        this.zeloService
          .setBulkZeloCategories(zeloIds, categories)
          .pipe(map(noop), catchError(oops()))
      ),
      tap(() => {
        this.segment.track('Bulk Action Categories');
        this.toastr.show(
          '',
          `Categories have been added to the selected Zelos`,
          {
            toastClass: 'zelo-toast zelo-toast-success'
          }
        );
      })
    )
  );

  upsertBeeSaveRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.upsertBeeSaveRows),
      concatMap(({ rows }) =>
        this.zeloService.upsertBeeSaveRows(rows).pipe(
          map(() =>
            ZeloNextActions.upsertBeeSaveRowsSuccess({
              rows
            })
          ),
          catchError((error) =>
            of(ZeloNextActions.upsertBeeSaveRowsFailure(error))
          )
        )
      )
    )
  );

  setCourseCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.setCourseCertificate),
      concatMap(({ zeloId, certificate }) =>
        this.zeloService.setCourseCertificate(zeloId, certificate).pipe(
          map((payload) =>
            ZeloNextActions.setCourseCertificateSuccess(payload)
          ),
          catchError(oops())
        )
      )
    )
  );

  fetchCourseCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.fetchCourseCertificate),
      concatMap(({ zeloId }) =>
        this.zeloService.getCourseCertificate(zeloId).pipe(
          map((payload) =>
            ZeloNextActions.fetchCourseCertificateSuccess(payload)
          ),
          catchError(oops())
        )
      )
    )
  );

  refreshZeloHTML$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloNextActions.refreshZeloHTML),
      withLatestFrom(this.store.select(getSelectedZeloSavingStatus)),
      concatMap(([{ zeloId }, savingStatus]) => {
        if (savingStatus) {
          return this.actions$.pipe(
            ofType(
              ZeloNextActions.upsertZeloChannelMessagesSuccess,
              ZeloNextActions.upsertZeloChannelMessagesFailure
            ),
            concatMap(() => {
              return this.zeloService.getChannelMessageHTML(zeloId).pipe(
                map((emailMessage) =>
                  ZeloNextActions.refreshZeloHTMLSuccess(emailMessage)
                ),
                catchError(oops())
              );
            })
          );
        } else {
          return this.zeloService.getChannelMessageHTML(zeloId).pipe(
            map((emailMessage) =>
              ZeloNextActions.refreshZeloHTMLSuccess(emailMessage)
            ),
            catchError(oops())
          );
        }
      }),
      catchError(oops())
    )
  );
}
