import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { ACTIVITY_TIMEOUT, SESSION_TIMEOUT } from '../../../shared/util';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'zlo-idle-dialog',
  template: `
    <h1 class="heading h1 purple-500 center margin-tl">
      {{ 'auth.idle.title' | translate }}
    </h1>

    <p class="body-text center">
      {{ 'auth.idle.message' | translate: { number: getSessionTimeout() } }}
    </p>

    <div class="flex center gap-32 margin-bl margin-tl">
      <button
        id="idle-dialog-stay-logged-in"
        class="button success min-width-120"
        mat-button
        mat-dialog-close="stay-logged-in"
      >
        {{ 'auth.idle.stay-logged-in' | translate }}
      </button>

      <button
        id="idle-dialog-log-out-now"
        class="button"
        mat-button
        mat-dialog-close="log-out-now"
      >
        {{ 'auth.idle.log-out-now' | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }

      button {
        min-width: 148px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogClose, TranslatePipe]
})
export class IdleDialogComponent implements OnInit, OnDestroy {
  sessionTimeout = (SESSION_TIMEOUT - ACTIVITY_TIMEOUT) / 1000 / 60;
  counter: ReturnType<typeof setInterval> = null;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<IdleDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.url.endsWith('expired-session')),
        tap(() => {
          this.dialogRef.close();
        })
      )
      .subscribe();

    timer(SESSION_TIMEOUT - ACTIVITY_TIMEOUT).subscribe(() => {
      this.dialogRef.close('log-out-now');
    });

    this.counter = setInterval(() => {
      if (this.sessionTimeout > 0) {
        this.sessionTimeout = this.sessionTimeout - 1;
        this.cdr.detectChanges();
      }
    }, 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.counter);
  }

  getSessionTimeout(): number {
    return this.sessionTimeout;
  }
}
