<div>
  <main>
    <div class="flex column center align-center padding-m">
      <div>
        <h1 *ngIf="!providerSession" class="heading h0 purple-500">
          {{ 'auth.registration.header' | translate }}
        </h1>
        <h1 *ngIf="providerSession" class="heading h0 purple-500">
          Welcome!
        </h1>
        <div class="flex row gap-16 vertical-center padding-bm">
          <div class="chip green">{{ tier | titlecase }} Plan</div>
          <p class="body-text gray-600">
            Start your 14-day free trial!
          </p>
        </div>
        <div class="form-container card card-shadow">
          <ng-container *ngIf="routerState$ | async as routerState">
            <zlo-registration-form
              [tier]="tier"
              (submitted)="onSubmit($event, routerState.url)"
            >
            </zlo-registration-form>
          </ng-container>
        </div>
        <div class="flex center padding-tl">
          <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
        </div>
      </div>
    </div>
    <div class="feature-container flex column center">
      <img
        ngSrc="../../../assets/images/graphic-elements/signup-feature.jpg"
        priority="true"
        width="1800"
        height="1031"
        alt="A screenshot of the Zelo dashboard"
      />
    </div>
  </main>
</div>
