<header *ngIf="user" class="flex shadow regular">
  <div
    *ngIf="dbHostname && dbHostname !== 'zelo-production'"
    style="
      position: absolute;
      width: 85%;
      opacity: 0.05;
      text-transform: uppercase;
      text-align: center;
      font-size: 48pt;
      z-index: -1;
    "
  >
    {{ dbHostname }}
  </div>

  <zlo-icon
    name="zelo-logo"
    width="72px"
    class="zelo-logo"
    [routerLink]="['/zelo']"
  ></zlo-icon>

  <div style="position: absolute; left: 0;">
    <zlo-privacy-consent></zlo-privacy-consent>
  </div>

  <nav class="flex between grow margin-ll">
    <section class="app-navigation">
      <a
        *ngFor="let item of appNavigationItems"
        [routerLink]="item.path"
        [routerLinkActive]="'active'"
        [id]="item.id"
        class="navigation-links"
      >
        {{ item.translationKey | translate }}
      </a>
    </section>

    <section class="org-title-container">
      <p class="org-title">
        {{ user.organization.name }}
      </p>
      <p *ngIf="departmentNameToShow" class="org-title">
        {{ ', ' + departmentNameToShow }}
      </p>
    </section>

    <section
      (focus)="onFocus()"
      (blur)="onBlur()"
      (clickOutside)="hideMenu()"
      attachOutsideOnClick="true"
      delayClickOutsideInit="true"
      (keypress)="onKeypress($event)"
      class="dropdown parrent"
      id="open-global-dropdown"
      tabindex="0"
      aria-owns="avatar-menu"
      attr.aria-label="{{ 'menu.avatar' | translate }}"
    >
      <div class="flex cursor-pointer" aria-hidden="true">
        <p class="marginless">{{ user.firstname }} {{ user.lastname }}</p>
        <zlo-icon
          name="user-circle"
          width="24px"
          class="icon margin-ls username"
        >
        </zlo-icon>
      </div>
      <ul
        id="avator-menu"
        role="menu"
        *ngIf="menuOpen"
        class="card dropdown child shadow medium flex column"
      >
        <li
          *ngFor="
            let option of user.roles.isAdmin
              ? dropdownOptionsAdmin
              : dropdownOptionsManager;
            last as isLast
          "
          [routerLink]="option.path"
          (click)="hideMenu(); actionHandler(option.action)"
          [id]="option.id"
          class="dropdown dropdown-link cursor-pointer"
          (blur)="onBlur()"
          role="menuitem"
          tabindex="0"
        >
          {{ option.translationKey | translate }}
        </li>
      </ul>
    </section>
  </nav>
</header>
