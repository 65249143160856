import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  EmployeeActionTypes,
  UpdateEmployee,
  UpdateEmployeeSuccess,
  UpdateEmployeeFailure,
  UpdateEmployees,
  UpdateEmployeesSuccess,
  UpdateEmployeesFailure,
  AddEmployee,
  AddEmployeeSuccess,
  AddEmployeeFailure,
  AddEmployees,
  AddEmployeesFailure,
  AddEmployeesSuccess,
  SearchOrganizationEmployees,
  SearchOrganizationEmployeesSuccess,
  SearchOrganizationEmployeesFailure,
  RemoveEmployees,
  RemoveEmployeesFailure,
  RemoveEmployeesSuccess,
  RestoreEmployees,
  RestoreEmployeesSuccess,
  RestoreEmployeesFailure,
  LoadDeletedEmployees,
  LoadDeletedEmployeesSuccess,
  HardDeleteEmployees,
  HardDeleteEmployeesSuccess,
  HardDeleteEmployeesFailure,
  DownloadOrganizationReportSuccess,
  DownloadOrganizationReportFailure
} from '../actions/employee.actions';
import {
  map,
  exhaustMap,
  catchError,
  tap,
  concatMap,
  switchMap,
  mergeMap
} from 'rxjs/operators';
import { EmployeeService } from '../services/employee.service';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Employee } from '../../../../types';
import { Go } from '../../../core/actions/router.actions';
import { TranslateService } from '@ngx-translate/core';
import { getZeloApplicationError, xlsxOperator } from '../../shared/util';
import { Router } from '@angular/router';
import { LoadZeloGroupsActivityFailure } from '../../dashboard/actions/dashboard.actions';
import { SegmentService } from '../../../shared/services/segment.service';
import { StatisticsService } from '../../zelo/services/statistics.service';
import { SelectedUserService } from '../../zelo/services/selected-user.service';
import { fetchOrg } from '../../zelo/actions/zelo.next.actions';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class EmployeeEffects {
  updateEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEmployee>(EmployeeActionTypes.UpdateEmployee),
      concatMap((action) =>
        this.employeeService.updateEmployee(action.payload).pipe(
          map(
            (updatedEmployee: Employee) =>
              new UpdateEmployeeSuccess(updatedEmployee)
          ),
          catchError((error) => of(new UpdateEmployeeFailure(error)))
        )
      )
    )
  );

  updateEmployeeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateEmployeeSuccess>(
          EmployeeActionTypes.UpdateEmployeeSuccess
        ),
        map((action) => action.payload),
        tap((employee) => {
          this.toastr.show(
            '',
            `The data for ${employee.firstname} is now updated`,
            {
              toastClass: 'zelo-toast zelo-toast-success'
            }
          );
          this.selectedUserService.emitChange({ user: employee });
          this.segment.track('Employee updated');
        }),
        switchMap(() => of(this.store.dispatch(fetchOrg())))
      ),
    { dispatch: false }
  );

  updateEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEmployees>(EmployeeActionTypes.UpdateEmployees),
      map((action) => action.payload),
      concatMap((action) =>
        this.employeeService.updateEmployees(action).pipe(
          map(
            (updatedEmployees: Employee[]) =>
              new UpdateEmployeesSuccess(updatedEmployees)
          ),
          catchError((error) => of(new UpdateEmployeesFailure(error)))
        )
      )
    )
  );

  updateEmployeesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateEmployeesSuccess>(
          EmployeeActionTypes.UpdateEmployeesSuccess
        ),
        map((action) => action.payload),
        tap((employees: Employee[]) => {
          this.toastr.show(
            '',
            `The data for ${employees?.length} recipient${
              employees.length > 1 ? 's' : ''
            } is now updated`,
            {
              toastClass: 'zelo-toast zelo-toast-success'
            }
          );
        })
      ),
    { dispatch: false }
  );

  addEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddEmployee>(EmployeeActionTypes.AddEmployee),
      exhaustMap((action) => {
        let op;
        if (action.payload.id === 'new') {
          op = this.employeeService.addEmployee(action.payload);
        } else {
          op = this.employeeService.updateEmployee(action.payload);
        }

        return op.pipe(
          mergeMap((user) => [
            action.payload.id === 'new'
              ? new AddEmployeeSuccess(user)
              : new UpdateEmployeeSuccess(user)
          ]),
          catchError((error) => of(new AddEmployeeFailure(error)))
        );
      })
    )
  );

  addEmployeeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddEmployeeSuccess>(EmployeeActionTypes.AddEmployeeSuccess),
        map((action) => action.payload),
        tap((employee) => {
          this.toastr.show(`${employee.firstname} is added`, 'Success', {
            toastClass: 'zelo-toast zelo-toast-success'
          });

          this.router.navigate(['/employee/list']);
          this.segment.track('Recipient Added');
        }),
        switchMap(() => of(this.store.dispatch(fetchOrg())))
      ),
    { dispatch: false }
  );

  addEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddEmployees>(EmployeeActionTypes.AddEmployees),
      map((action) => action.payload),
      concatMap(
        ({ employees: employeesToAdd, replaceTerms, relateDepartments }) =>
          this.employeeService
            .addEmployees(employeesToAdd, replaceTerms, relateDepartments)
            .pipe(map((batches) => new AddEmployeesSuccess(batches)))
      ),
      catchError((error) => of(new AddEmployeesFailure(error)))
    )
  );

  addEmployeesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddEmployeesSuccess>(EmployeeActionTypes.AddEmployeesSuccess),
      map((action) => action.payload),
      tap((batches: []) => {
        const key = 'recipients.import.jobIsRunning';
        const eta = Math.max(2, Math.min(10, batches.flat().length));
        const title = this.translate.instant('recipients.import.jobTitle');
        const msg = this.translate.instant(key, { eta });

        const opts = {
          timeOut: 10000,
          toastClass: 'zelo-toast zelo-toast-success'
        };

        this.toastr.show(msg, title, opts);

        this.segment.track('Import Recipients');
      }),
      map(() => {
        return new Go({ path: ['/employee/list'] });
      })
    )
  );

  removeEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveEmployees>(EmployeeActionTypes.RemoveEmployees),
      map((action) => action.payload),
      exhaustMap((employees) =>
        this.employeeService.removeEmployees(employees).pipe(
          mergeMap(() => [new RemoveEmployeesSuccess(employees)]),
          catchError((error) => of(new RemoveEmployeesFailure(error)))
        )
      )
    )
  );

  removeEmployeesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RemoveEmployeesSuccess>(
          EmployeeActionTypes.RemoveEmployeesSuccess
        ),
        map((action) => action.payload),
        tap((employees: Employee[]) => {
          this.toastr.show(
            '',
            `${employees.length} recipient${
              employees.length > 1 ? 's were' : ' was'
            } deleted`,
            {
              toastClass: 'zelo-toast zelo-toast-success'
            }
          );
          this.segment.track('Recipients Deleted');
        }),
        switchMap(() => of(this.store.dispatch(fetchOrg())))
      ),
    { dispatch: false }
  );

  hardDeleteEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<HardDeleteEmployees>(EmployeeActionTypes.HardDeleteEmployees),
      map((action) => action.payload),
      exhaustMap((employees) =>
        this.employeeService.hardDeleteEmployees(employees).pipe(
          mergeMap(() => [new HardDeleteEmployeesSuccess(employees)]),
          catchError((error) => of(new HardDeleteEmployeesFailure(error)))
        )
      )
    );
  });

  hardDeleteEmployeesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<HardDeleteEmployeesSuccess>(
          EmployeeActionTypes.HardDeleteEmployeesSuccess
        ),
        map((action) => action.payload),
        tap((employees: Employee[]) => {
          this.toastr.show(
            '',
            `${employees.length} recipient${
              employees.length > 1 ? 's were' : ' was'
            } completely deleted`,
            {
              toastClass: 'zelo-toast zelo-toast-success'
            }
          );
          this.segment.track('Recipients Hard Deleted');
        })
      );
    },
    { dispatch: false }
  );

  searchOrganizationEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchOrganizationEmployees>(
        EmployeeActionTypes.SearchOrganizationEmployees
      ),
      switchMap((action) => {
        return this.employeeService.searchEmployees(action.payload.user).pipe(
          map((employees) => new SearchOrganizationEmployeesSuccess(employees)),
          catchError((error) =>
            of(new SearchOrganizationEmployeesFailure(error.message))
          )
        );
      })
    )
  );

  employeeErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | UpdateEmployeeFailure
          | AddEmployeeFailure
          | RemoveEmployeesFailure
          | AddEmployeesFailure
          | HardDeleteEmployeesFailure
        >(
          EmployeeActionTypes.UpdateEmployeeFailure,
          EmployeeActionTypes.AddEmployeeFailure,
          EmployeeActionTypes.RemoveEmployeesFailure,
          EmployeeActionTypes.AddEmployeesFailure,
          EmployeeActionTypes.HardDeleteEmployeesFailure
        ),
        map((action) => action.payload),
        tap((error) => {
          const err = getZeloApplicationError(error);

          let title = `Something happened there. It might be this: ${error.name} (${error.statusText}) (Employee)`;
          let subTitle = 'Try again and contact us if the error persists.';

          if (err.isApplicationError) {
            title = this.translate.instant(`shared.errors.${err.errCode}`);
            subTitle = this.translate.instant('shared.errors.contactSupport');
          }

          this.toastr.show(subTitle, title, {
            toastClass: 'zelo-toast zelo-toast-alert'
          });
        })
      ),
    { dispatch: false }
  );

  restoreDeletedEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RestoreEmployees>(EmployeeActionTypes.RestoreEmployees),
      exhaustMap((action) => {
        return this.employeeService
          .restoreDeletedEmployees(action.payload)
          .pipe(
            map((employees) => new RestoreEmployeesSuccess(employees)),
            catchError((error) =>
              of(new RestoreEmployeesFailure(error.message))
            )
          );
      })
    )
  );

  restoreDeletedEmployeesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<RestoreEmployeesSuccess>(
          EmployeeActionTypes.RestoreEmployeesSuccess
        ),
        map((action) => action.payload),
        tap((employees: Employee[]) => {
          this.toastr.show(
            '',
            `${employees.length} recipient${
              employees.length > 1 ? 's were' : ' was'
            } restored`,
            {
              toastClass: 'zelo-toast zelo-toast-success'
            }
          );
          this.segment.track('Recipients Restored');
        })
      );
    },
    { dispatch: false }
  );

  loadDeletedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadDeletedEmployees>(EmployeeActionTypes.LoadDeletedEmployees),
      switchMap(() => {
        return this.employeeService.loadDeletedEmployees().pipe(
          map(
            (deletedEmployees) =>
              new LoadDeletedEmployeesSuccess(deletedEmployees)
          ),
          catchError((error) =>
            of(new LoadZeloGroupsActivityFailure(error.message))
          )
        );
      })
    )
  );

  downloadOrganizationReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActionTypes.DownloadOrganizationReport),
      tap(() => {
        this.toastr.show(
          'Please wait a few seconds.',
          'The file is being generated.',
          {
            toastClass: 'zelo-toast zelo-toast-success'
          }
        );
      }),
      exhaustMap(() =>
        this.statisticsService.getOrganizationReport().pipe(
          // tap(() => {
          //   this.segment.track('Organization employees report Downloaded', {
          //     userId: this.authService.getUserId()
          //   });
          // }),
          // eslint-disable-next-line no-magic-numbers
          xlsxOperator('Zelo Users ' + new Date().toDateString()),
          map(() => new DownloadOrganizationReportSuccess()),
          catchError((error) =>
            of(new DownloadOrganizationReportFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private employeeService: EmployeeService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private segment: SegmentService,
    private statisticsService: StatisticsService,
    private selectedUserService: SelectedUserService,
    private store: Store
  ) {}
}
