import { Store } from '@ngrx/store';
import * as ZeloActions from './../actions/zelo.actions';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { ZeloService } from '../services/zelo.service';
import {
  catchError,
  exhaustMap,
  concatMap,
  map,
  tap,
  debounceTime,
  switchMap
} from 'rxjs/operators';
import { of, from } from 'rxjs';
import { DistributorJob, Zelo, ZeloValidationError } from 'src/types';
import { Go } from '../../../core/actions/router.actions';
import { StatisticsService } from '../services/statistics.service';
import { ZeloValidator } from '../utils/validations';
import { xlsxOperator, getZeloApplicationError } from '../../shared/util';
import * as fromZelos from '../reducers';
import { TranslateService } from '@ngx-translate/core';
import * as ZeloNextActions from '../actions/zelo.next.actions';
import {
  brief,
  huzza,
  selectZeloById,
  selectZeloByIdSuccess,
  setZeloSteps
} from '../actions/zelo.next.actions';
import { SegmentService } from 'src/app/shared/services/segment.service';

function parseValidationError(error: ZeloValidationError, zelo: Zelo) {
  error.inner
    .filter((val) => val.path.includes('steps['))
    .map((val) => val.path.split('[')[1].split(']')[0])
    .map((val) => Number(val))
    .forEach((idx) => {
      const step = zelo.steps[idx];
      if (!step.error) {
        zelo.steps[idx] = { ...step, error: true };
      }
    });

  error.stepsErrorCount = zelo.steps.filter((step) => !!step.error).length;

  error.hasInfoStepError = !!error.inner.filter(
    (val) => !val.path.includes('steps[')
  ).length;

  return error;
}

@Injectable({ providedIn: 'root' })
export class ZeloEffects {
  constructor(
    private actions$: Actions,
    private zelosService: ZeloService,
    private statisticsService: StatisticsService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private store: Store<fromZelos.State>,
    private segment: SegmentService
  ) {}

  cancelZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.CancelZelo),
      exhaustMap((zelo: Zelo) => {
        /* cancel or pause sending zelo */
        return this.zelosService.cancelZelo(zelo).pipe(
          map((result) => {
            return ZeloActions.CancelZeloSuccess({ zelo, result });
          }),
          catchError((error) => of(ZeloActions.CancelZeloFailure(error)))
        );
      })
    )
  );

  cancelZeloSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.CancelZeloSuccess),
      tap(({ zelo, result }) => {
        if (result.status === 'draft') {
          this.store.dispatch(selectZeloById({ zeloId: zelo.id }));
          this.store.dispatch(huzza(ZeloActions.CancelZeloSuccess.type)());
        }
      }),
      tap(({ zelo, result }) => {
        if (result.status !== 'draft' && result.count === 0) {
          this.store.dispatch(selectZeloById({ zeloId: zelo.id }));
          this.store.dispatch(brief(ZeloActions.CancelZeloPaused.type)());
        }
      }),
      tap(({ zelo, result }) => {
        if (result.status !== 'draft' && result.count > 0) {
          this.store.dispatch(selectZeloById({ zeloId: zelo.id }));
          this.store.dispatch(huzza(ZeloActions.PauseZeloSuccess.type)());
        }
      }),
      map(({ zelo }) => new Go({ path: ['/zelo', zelo.id] }))
    )
  );

  resumeZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.ResumeZelo),
      exhaustMap((zelo: Zelo) => {
        /* resume sending zelo */
        return this.zelosService.resumeZelo(zelo).pipe(
          map((jobs: DistributorJob[]) =>
            ZeloActions.ResumeZeloSuccess({ zelo, jobs })
          ),
          catchError((error) => of(ZeloActions.ResumeZeloFailure(error)))
        );
      })
    )
  );

  resumeZeloSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.ResumeZeloSuccess),
      tap(({ zelo }) => {
        this.store.dispatch(selectZeloById({ zeloId: zelo.id }));
        this.toastr.show(
          this.translate.instant(
            'composer.shared.emptyState.resumeZelo.description'
          ),
          'Success',
          {
            toastClass: 'zelo-toast zelo-toast-success'
          }
        );
      }),
      map(({ zelo }) => new Go({ path: ['/zelo', zelo.id] }))
    )
  );

  sendReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.SendZeloReminder),
      exhaustMap(({ zeloId, content, interval, limit, until }) => {
        /* send reminder */
        this.segment.track('Sent Reminder', {
          zeloId: zeloId
        });
        return this.zelosService
          .sendReminder({
            zeloId,
            content,
            interval,
            limit,
            until
          })
          .pipe(
            map(() => ZeloActions.SendZeloReminderSuccess()),
            catchError((error) =>
              of(ZeloActions.SendZeloReminderFailure(error))
            )
          );
      })
    )
  );

  sendZeloReminderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloActions.SendZeloReminderSuccess),
        tap(() => {
          this.toastr.show(this.translate.instant('toasts.success'), '', {
            toastClass: 'zelo-toast zelo-toast-success'
          });
        })
      ),
    { dispatch: false }
  );

  testChannelMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.TestChannelMessage),
      concatMap(
        ({
          zeloId,
          channelId,
          channel,
          email,
          sms,
          whatsapp,
          slack,
          teams,
          recipientIds,
          senderId
        }) => {
          this.segment.track('Test Message Sent', {
            zeloId: zeloId
          });
          /* send test */
          return this.zelosService
            .testChannelMessage({
              zeloId,
              channelId,
              channel,
              email,
              sms,
              whatsapp,
              slack,
              teams,
              recipientIds,
              senderId
            })
            .pipe(
              map((result) => ZeloActions.TestChannelMessageSuccess(result)),
              catchError((error) =>
                of(ZeloActions.TestChannelMessageFailure(error))
              )
            );
        }
      )
    )
  );

  testChannelMessageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ZeloActions.TestChannelMessageSuccess),
        tap((result) => {
          if (result.channel) {
            const i18n = `testChannel.${result.channel}.label`;
            const ch = this.translate.instant(i18n);
            const str = this.translate.instant('testChannel.sending', { ch });
            const toastClass = 'zelo-toast zelo-toast-success';
            this.toastr.show(str, 'Success', { toastClass });
          }
        })
      ),
    { dispatch: false }
  );

  validateZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.ValidateZelo),
      exhaustMap((zelo) =>
        from(
          ZeloValidator.validate(zelo, { strict: true, abortEarly: false })
        ).pipe(
          map((result: any) => {
            return result;
          }),
          catchError((error: ZeloValidationError) => {
            console.warn(error);
            return of(
              ZeloActions.ValidateZeloFailure({
                zelo,
                error: parseValidationError(error, zelo)
              })
            );
          })
        )
      )
    )
  );

  sendZeloToOthers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.SendZeloToOthers),
      exhaustMap(({ zelo, recipientIds, reprocessFilters, v2 }) =>
        this.zelosService
          .sendZeloToOthers({
            zeloId: zelo.id,
            recipientIds,
            reprocessFilters,
            v2
          })
          .pipe(
            tap(({ zelo: updatedZelo }) => {
              // notifies subscribers of getSelectedZelo
              this.store.dispatch(selectZeloByIdSuccess({ zelo: updatedZelo }));
            }),
            switchMap(() => {
              this.toastr.show(
                `${zelo.title} is being sent to the additional recipients.`,
                `Success`,
                {
                  toastClass: 'zelo-toast zelo-toast-success'
                }
              );
              this.segment.track('Sent To Others', {
                zeloId: zelo.id
              });
              return [ZeloNextActions.noop()];
            }),
            catchError((error) =>
              of(ZeloActions.SendZeloToOthersFailure(error))
            )
          )
      )
    )
  );

  createZelo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.CreateZelo),
      map((action) => action.payload),
      exhaustMap((zelo) => {
        return this.zelosService.addZelo(zelo).pipe(
          map((createdZelo) => ZeloActions.CreateZeloSuccess(createdZelo)),
          catchError((error) => of(ZeloActions.CreateZeloFailure(error)))
        );
      })
    )
  );

  newZeloSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.CreateZeloSuccess),
      switchMap((zelo) => {
        this.segment.track('Message Created', {
          zeloId: zelo.id
        });

        return [new Go({ path: ['/zelo', zelo.id] })];
      })
    )
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.DownloadReportZelo),
      map((action) => action.payload),
      tap((zelo) => {
        this.segment.track('Report Downloaded', {
          zeloId: zelo.id
        });
        this.toastr.show(
          'Please wait a few seconds.',
          'The file is being generated.',
          {
            toastClass: 'zelo-toast zelo-toast-success'
          }
        );
      }),
      exhaustMap((zelo) =>
        this.statisticsService.getZeloReport(zelo.id).pipe(
          // eslint-disable-next-line no-magic-numbers
          xlsxOperator(
            'Report - ' +
              zelo.title.substring(0, 180) +
              (zelo.title.length > 180 ? '...' : '')
          ),
          map(() => ZeloActions.DownloadReportZeloSuccess()),
          catchError((error) =>
            of(ZeloActions.DownloadReportZeloFailure(error))
          )
        )
      )
    )
  );

  zelosStepSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.UpdateZeloStep),
      debounceTime(1000), // eslint-disable-line  no-magic-numbers
      map((payload) => {
        const zelo = payload;
        return setZeloSteps({
          id: zelo.id,
          steps: zelo.steps,
          isValid: zelo.isValid
        });
      })
    )
  );

  loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.LoadChannels),
      exhaustMap(() => {
        return this.zelosService.getChannels().pipe(
          map((channels) => ZeloActions.LoadChannelsSuccess({ channels })),
          catchError((error) => of(ZeloActions.LoadChannelsFailure(error)))
        );
      })
    )
  );

  loadZeloCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZeloActions.LoadZeloCategories),
      exhaustMap(() => {
        return this.zelosService.getCategories().pipe(
          map((response) =>
            ZeloActions.LoadZeloCategoriesSuccess({ payload: response })
          ),
          catchError((error) =>
            of(ZeloActions.LoadZeloCategoriesFailure(error))
          )
        );
      })
    )
  );

  zelosError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ZeloActions.CreateZeloFailure,
          ZeloActions.SendZeloFailure,
          ZeloActions.SendZeloToOthersFailure,
          ZeloActions.CancelZeloFailure,
          ZeloActions.ResumeZeloFailure,
          ZeloActions.DownloadReportZeloFailure,
          ZeloActions.SendZeloReminderFailure,
          ZeloActions.TestChannelMessageFailure
        ),
        tap((error) => {
          const msg = error.error?.message;
          const i18n = msg ? `shared.errors.${msg}` : '';
          let errmsg = '';

          if (msg) errmsg = this.translate.instant(i18n);

          if (errmsg) {
            return this.toastr.show('', errmsg, {
              toastClass: 'zelo-toast zelo-toast-alert'
            });
          }

          // TODO: refactor error handling logic
          if (
            error.error &&
            error.error.message === 'the choosen groups have no users'
          ) {
            return this.toastr.show(
              this.translate.instant('shared.errors.noNewUsersInGroup'),
              this.translate.instant('shared.errors.errorTitle'),
              {
                toastClass: 'zelo-toast zelo-toast-alert'
              }
            );
          }

          // Tier Limit Error
          if (error.error && error.error.message === 'limitExceeded') {
            return this.toastr.show(
              this.translate.instant('shared.errors.tierLimitExceeded'),
              this.translate.instant('shared.errors.errorTitle'),
              {
                toastClass: 'zelo-toast zelo-toast-alert'
              }
            );
          }

          const err = getZeloApplicationError(error);

          let title = `Something happened there. It might be this: ${
            error.name || ''
          } (${error.statusText || ''}) (Zelos)`;
          let subTitle = 'Try again and contact us if the error persists.';

          if (err.isApplicationError) {
            title = this.translate.instant(`shared.errors.${err.errCode}`);
            subTitle = this.translate.instant('shared.errors.contactSupport');
          }

          this.toastr.show(subTitle, title, {
            toastClass: 'zelo-toast zelo-toast-alert'
          });
        })
      ),
    { dispatch: false }
  );
}
