import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input
} from '@angular/core';

import { Validators, ReactiveFormsModule } from '@angular/forms';
import { Registration } from 'src/types';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserValidators } from '../user.util';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'zlo-registration-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  imports: [ReactiveFormsModule, NgIf, NgClass, TranslatePipe]
})
export class RegistrationFormComponent implements OnInit {
  @Input() tier: string;
  @Output()
  submitted = new EventEmitter<Registration>();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  nameCheckStatus = '';
  nameCheckCollision = false;

  ngOnInit(): void {
    this.form.get('tier').setValue(this.tier);
  }

  nameCheckClass = () =>
    `name-check-${this.nameCheckCollision ? this.nameCheckStatus : ''}`;

  nameCheckText = () =>
    this.nameCheckStatus
      ? this.translate.instant(`auth.errors.${this.nameCheckStatus}`)
      : '';

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', UserValidators.requiredEmail),
      password: new UntypedFormControl('', UserValidators.requiredPassword),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      orgName: new UntypedFormControl('', Validators.required),
      tier: new UntypedFormControl('starter', Validators.required)
    },
    { updateOn: 'change' }
  );

  nameCheck(event: any) {
    const url = `${environment.api}/v1/organizations/name-check`;
    this.http
      .post(url, { name: event.currentTarget.value })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.nameCheckStatus = res.status;

        if (!this.nameCheckCollision && this.nameCheckStatus === 'collision')
          this.nameCheckCollision = true;

        this.cdr.markForCheck();
      });
  }

  submit() {
    if (this.form.valid && this.nameCheckStatus !== 'collision') {
      const registration: Registration = {
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        email: this.form.value.email,
        password: this.form.value.password,
        orgName: this.form.value.orgName,
        tier: this.form.value.tier
      };

      this.submitted.emit(registration);
    }
  }
}
