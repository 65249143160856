import { DOCUMENT } from '@angular/common';
import { updateCurrentUserSuccess } from '../../adminPortal/profile/actions/settings.actions';
import { AuthenticateRepsonse, User } from 'src/types';
import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { tap, map, exhaustMap, catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import * as AuthActions from '../actions/auth.actions';
import { Go } from '../../core/actions/router.actions';
import { PasswordChange } from 'src/types';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { TOKEN_KEY, USER_KEY, PERMISSIONS_KEY, ENV_KEY } from '../user.util';
import { SegmentService } from '../../../app/shared/services/segment.service';
import { Store } from '@ngrx/store';
import { clearState } from 'src/app/adminPortal/zelo/actions/zelo.next.actions';
import { Router } from '@angular/router';

const persistLoginArtifacts = (loginParams: AuthenticateRepsonse) => {
  const token = loginParams.token || '';
  const dbHostname = loginParams.dbHostname || '';
  const permissions = loginParams.user?.permissions;

  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  if (dbHostname) localStorage.setItem(ENV_KEY, dbHostname);
};

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private segment: SegmentService,
    private store: Store,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Login),
      exhaustMap((auth) =>
        this.authService.login(auth).pipe(
          tap(() => {
            this.store.dispatch(clearState());
          }),
          map((res: AuthenticateRepsonse) => {
            if (res.status?.split('|')[0] === 'otp') {
              const status = res.status.split('|')[1];
              const channel = res.status.split('|')[2];
              return AuthActions.Otp({ status, channel });
            }

            persistLoginArtifacts(res);

            if (res.redirectUrl && res.redirectUrl !== 'qa') {
              this.document.location.href = res.redirectUrl;
              return AuthActions.Logout();
            }

            return AuthActions.LoginSuccess(res);
          }),
          catchError((error) => {
            return of(AuthActions.LoginFailure(error));
          })
        )
      )
    )
  );

  loginSSO$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginSSO),
      exhaustMap((auth) =>
        this.authService.loginSSO(auth).pipe(
          tap(() => {
            this.store.dispatch(clearState());
          }),
          map((res: { redirectUrl: string }) => {
            const redirectUrl = res.redirectUrl || '';
            if (redirectUrl) {
              return (window.location.href = redirectUrl);
            }
          }),
          catchError((error) => {
            console.log(error);
            return of(AuthActions.LoginFailure(error));
          })
        )
      )
    )
  );

  loginSSOCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginSSOCode),
      exhaustMap((payload) =>
        this.authService.loginSSOCode(payload.code).pipe(
          map((res: AuthenticateRepsonse) => {
            persistLoginArtifacts(res);
            return AuthActions.LoginSuccess(res);
          }),
          catchError((error) => {
            return of(AuthActions.LoginSSOCodeFailure(error));
          })
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Forgot),
      map((action) => action.payload),
      exhaustMap((email: string) =>
        this.authService.forgot(email).pipe(
          map(() => AuthActions.ForgotSuccess({ email })),
          tap(() => {
            console.log(email);
            this.segment.track('Forgot Password');
          }),
          catchError((error) => {
            return of(AuthActions.ForgotFailure(error));
          })
        )
      )
    )
  );

  forgotSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ForgotSuccess),
        tap((action) => {
          this.toastr.show('', 'Email sent with the new Password', {
            toastClass: 'zelo-toast zelo-toast-success'
          });
          this.router.navigate(['/auth/login'], {
            queryParams: { email: action.email }
          });
        })
      ),
    { dispatch: false }
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Change),
      exhaustMap((creds: PasswordChange) =>
        this.authService.change(creds).pipe(
          map(() => AuthActions.ChangeSuccess()),
          catchError((error) => {
            return of(AuthActions.ChangeFailure(error));
          })
        )
      )
    )
  );

  changePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ChangeSuccess),
      tap(() => {
        this.segment.track('Password Changed');
        this.toastr.show('', 'The password is now updated', {
          toastClass: 'zelo-toast zelo-toast-success'
        });
      }),
      map(() => new Go({ path: ['/'] }))
    )
  );

  // Completely working loginSuccess$
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginSuccess),
      tap((action) => {
        localStorage.setItem(USER_KEY, JSON.stringify(action.user));
        const userObject = action.user;
        const language =
          userObject.settings?.preferredLanguage ||
          userObject.organization.language;
        this.translate.use(language);
        this.segment.track('Signed In');
      }),
      map(() => new Go({ path: ['/zelo'] }))
    )
  );

  updateCurrentUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCurrentUserSuccess),
        tap((user) => {
          const permissions = user.permissions;

          const storedUser = JSON.parse(localStorage.getItem(USER_KEY));

          localStorage.setItem(
            USER_KEY,
            JSON.stringify({
              ...storedUser,
              ...user,
              settings: { ...user.settings }
            })
          );
          localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
          const language =
            user.settings?.preferredLanguage || user.organization.language;
          this.translate.use(language);

          this.toastr.show('', 'Recipient is now updated.', {
            toastClass: 'zelo-toast zelo-toast-success'
          });
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Init),
        switchMap(() => {
          return localStorage.getItem(TOKEN_KEY)
            ? this.authService.refreshToken()
            : EMPTY;
        }),
        tap(() => {
          const user: User = JSON.parse(localStorage.getItem(USER_KEY) || '{}');

          if (user && user.organization) {
            const language =
              user.settings?.preferredLanguage ||
              user.organization.language ||
              'spama';
            this.translate.use(language);
          }

          return user && user.organization;
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Logout),
      map(() => {
        this.segment.track('Signed Out');
        this.authService.deleteRefreshToken().subscribe();
        return AuthActions.LoginRedirect();
      })
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginRedirect),
        map(() => {
          localStorage.clear();
          return this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  expireSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ExpireSession),
        map(() => {
          return this.authService.logout('/auth/expired-session');
        })
      ),
    { dispatch: false }
  );

  overviewLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.OverviewLink),
      exhaustMap((action) => {
        return this.authService.generateOverviewLink(action.payload).pipe(
          tap(() => {
            this.segment.track('Overview Link Requested');
          }),
          map(() => AuthActions.OverviewLinkSuccess()),
          catchError((error) => {
            return of(AuthActions.OverviewLinkFailure(error));
          })
        );
      })
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Register),
      exhaustMap((action) =>
        this.authService.register(action).pipe(
          map((res: AuthenticateRepsonse) => {
            const { user, token, redirectUrl } = res;

            if (user && token) {
              persistLoginArtifacts(res);

              if (redirectUrl) {
                if (redirectUrl !== 'qa') {
                  this.segment.identifySegmentUser(user, {
                    isSignupFlow: true
                  });

                  this.segment.trackAnonymous('Signed Up', {
                    tier: user.organization.tier
                  });
                }

                this.document.location.href = redirectUrl;
                return AuthActions.Logout();
              }

              this.segment.trackAnonymous('Order Completed', {
                tier: user.organization.tier
              });

              return AuthActions.LoginSuccess(res);
            }
          }),
          catchError((error) => {
            return of(AuthActions.LoginFailure(error));
          })
        )
      )
    )
  );

  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.LoginFailure,
          AuthActions.ForgotFailure,
          AuthActions.ChangeFailure,
          AuthActions.OverviewLinkFailure
        ),
        tap((error) => {
          const toastOptions = {
            toastClass: 'zelo-toast zelo-toast-alert',
            timeOut: 10000,
            enableHtml: true
          };

          const errorCodes = {
            missingConnection: 0,
            serverError: 500
          };

          const message = {
            title: '',
            body: ''
          };

          switch (error.status) {
            case errorCodes.missingConnection: {
              message.body =
                'Please ensure that you are connected to the internet and try again';
              message.title = 'Connection to the internet lost';
              break;
            }

            case errorCodes.serverError: {
              message.body =
                'Something unexpected went wrong. Please try again';
              message.title = 'Server error';
              break;
            }

            default: {
              if (error.error && error.error.message) {
                const msg = error.error.message;

                if (['permissions', 'no_user', 'credentials'].includes(msg)) {
                  message.body = this.translate.instant(`auth.errors.${msg}`);
                } else {
                  message.body = error.error.message;
                  message.title = error.error.error;
                }
              } else {
                message.body =
                  'If you keep getting this error please take a screenshot of this message and send it to an administrator. ' +
                  JSON.stringify(error);
                message.title = 'Unexpected error';
              }
              break;
            }
          }

          return this.toastr.show(message.body, message.title, toastOptions);
        })
      ),
    { dispatch: false }
  );
}
