import { ApplicationConfig } from '@angular/core';

import { Title, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/core/containers/app.component';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from './app/app.routes';
import { combineReducers, provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  provideTranslateService,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { RouterEffects } from './app/core/effects/router.effects';
import { reducers as AuthReducers } from './app/auth/reducers';
import { AuthEffects } from './app/auth/effects/auth.effects';
import { TokenInterceptor } from './app/auth/services/token.interceptor.service';
import { provideToastr } from 'ngx-toastr';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from './environments/environment';
import {
  reducers as CoreReducers,
  CustomRouterStateSerializer
} from './app/core/reducers';
import { RouterStateSerializer } from '@ngrx/router-store';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './app/shared/services/paginatorI18n.service';
import { provideRouterStore } from '@ngrx/router-store';
import { metaReducers } from './app/core/reducers';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

const serviceProviders: ApplicationConfig['providers'] = [
  provideTranslateService({
    defaultLanguage: 'en',
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) =>
        new TranslateHttpLoader(http, './assets/i18n/', '.json'),
      deps: [HttpClient]
    }
  }),
  provideToastr({
    positionClass: 'toast-top-center',
    toastClass: 'zelo-toast',
    timeOut: 3000
  }),
  Title,
  {
    provide: RouterStateSerializer,
    useClass: CustomRouterStateSerializer
  },
  {
    provide: MatPaginatorIntl,
    useClass: PaginatorI18n,
    deps: [TranslateService]
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: { autoFocus: 'dialog', restoreFocus: true }
  }
];

const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    provideHttpClient(withInterceptors([TokenInterceptor])),
    provideStore(CoreReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    provideRouterStore(),
    provideState({
      name: 'auth',
      reducer: combineReducers(AuthReducers)
    }),
    provideEffects([AuthEffects, RouterEffects]),
    provideAnimations(),
    ...serviceProviders
  ]
};

if (!environment.production) {
  appConfig.providers.push(
    provideStoreDevtools({
      name: 'NgRx Zelo Store DevTools',
      logOnly: environment.production,
      connectInZone: true
    })
  );
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.log(err));
