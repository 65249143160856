import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionActivityService {
  constructor() {}

  private actionSource = new Subject<void>();

  activeActions$ = this.actionSource.asObservable();

  triggerActiveAction() {
    this.actionSource.next();
  }
}
