import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import { Zelo } from 'src/types';

import {
  fetchDistributorReport,
  fetchDistributorReportSuccess
} from '../actions/admin.actions';
import { ZeloService } from '../../zelo/services/zelo.service';

@Injectable()
export class AdminEffects {
  constructor(private actions$: Actions, private zeloService: ZeloService) {}

  fetchDistributorReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDistributorReport),
      concatMap((params) => {
        const id = (params.focus as Partial<Zelo>).id;
        let body = null;

        if (id) {
          body = { ids: [id] };
        }

        if (Array.isArray(params.focus)) {
          body = { ids: params.focus };
        }

        return this.zeloService
          .fetchDistributorReport(body)
          .pipe(map((snapshot) => fetchDistributorReportSuccess({ snapshot })));
      }),
      catchError(() => of(fetchDistributorReportSuccess(null)))
    )
  );
}
