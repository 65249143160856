import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { sampleTime, filter, map, tap, switchMap } from 'rxjs/operators';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { MatDialog } from '@angular/material/dialog';
import * as fromAuth from '../../../auth/reducers';
import {
  ExpireSession,
  SetSessionStateActive,
  SetSessionStateIdle
} from '../../../auth/actions/auth.actions';
import { IdleDialogComponent } from './idle-dialog.component';
import { Logout } from 'src/app/auth/actions/auth.actions';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SegmentService } from 'src/app/shared/services/segment.service';
import { fetchOrg } from '../../zelo/actions/zelo.next.actions';
import { LoadZeloCategories } from '../../zelo/actions/zelo.actions';
import { UserOrganization, Zelo, ZeloSummary } from 'src/types';
import { fetchDistributorReport } from '../actions/admin.actions';
import { getDistributorReportFocus } from '../reducers/admin.reducer';
import { TOKEN_KEY, USER_KEY } from 'src/app/auth/user.util';
import { ACTIVITY_TIMEOUT } from 'src/app/shared/util';
import { SessionActivityService } from '../services/session-activity.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { HeaderComponent } from '../../shared/components/header/header.component';

@Component({
  selector: 'zlo-admin',
  template: `
    <div class="admin-container">
      <!-- NAVIGATION BAR -->
      <zlo-header
        *ngIf="!hideMainMenu"
        [user]="user$ | async"
        (logout)="logout()"
      ></zlo-header>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: ``,
  imports: [NgIf, HeaderComponent, RouterOutlet, AsyncPipe]
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild('mainMenu', { static: true }) mainMenuElementRef: ElementRef;

  subscriptions: Subscription = new Subscription();
  expandSidenav = false;
  hideMainMenu = false;
  organization: UserOrganization = null;
  distributorReportFocus: Partial<Zelo> | Partial<ZeloSummary>[] = null;
  ticks = 0;
  tick = () => this.tock(this.ticks++);
  ticker: ReturnType<typeof setInterval> = null;

  user$ = this.store.pipe(select(fromAuth.getUser));
  userCanEdit$ = this.store.pipe(select(fromAuth.userCanEdit));

  public userActivityEvents: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll']
  ];

  isSessionIdle$ = this.store.pipe(select(fromAuth.isSessionIdle));

  constructor(
    private store: Store<fromAuth.State>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private segment: SegmentService,
    private sessionActivity: SessionActivityService
  ) {
    if (localStorage.getItem(TOKEN_KEY) && !localStorage.getItem(USER_KEY)) {
      this.store.dispatch(Logout());
    }
  }

  ngOnInit() {
    //this.segment.loadAnalyticsJS();
    this.segment.loadIntercom();
    this.store.dispatch(fetchOrg());
    this.store.dispatch(LoadZeloCategories());
    this.hideMainMenuFn();

    this.tick();
    this.ticker = setInterval(this.tick, 1000);

    this.subscriptions.add(
      this.isSessionIdle$.subscribe((idle) => {
        if (idle) {
          const idleRef = this.dialog.open(IdleDialogComponent, {
            minWidth: '50%'
          });

          idleRef.afterClosed().subscribe((result) => {
            if (result === 'log-out-now') {
              this.store.dispatch(ExpireSession());
            } else {
              this.store.dispatch(SetSessionStateActive());
            }
          });
        }
      })
    );

    this.subscriptions.add(
      merge(
        of(null),
        ...this.userActivityEvents.map((e) => fromEvent(e[0], e[1])),
        this.sessionActivity.activeActions$
      )
        .pipe(
          sampleTime(500),
          // Resets the timer on ANY user activity
          switchMap(() => timer(ACTIVITY_TIMEOUT)),
          map(() => this.store.dispatch(SetSessionStateIdle()))
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.user$.subscribe((user) => {
        if (user) {
          this.store.dispatch(SetSessionStateActive());
          this.segment.identifySegmentUser();
        } else {
          clearInterval(this.ticker);
          this.ticker = null;
          this.ticks = 0;
        }
      })
    );

    this.subscriptions.add(
      this.router.events
        .pipe(
          tap(() => {
            this.hideMainMenuFn();
          }),
          filter((event: any) => event instanceof NavigationEnd),
          map(() => {
            let child = this.activatedRoute.firstChild;
            while (child) {
              if (child.firstChild) {
                child = child.firstChild;
              } else if (child.snapshot.data && child.snapshot.data['title']) {
                return child.snapshot.data['title'];
              } else {
                return null;
              }
            }
            return null;
          })
        )
        .subscribe((title: string) => {
          this.titleService.setTitle(title ? title + ' | Zelo' : 'Zelo');
          (<any>window).analytics?.page();
        })
    );

    this.subscriptions.add(
      this.store.pipe(select(fromAuth.getOrganization)).subscribe((org) => {
        this.organization = org;
      })
    );

    this.subscriptions.add(
      this.store.pipe(select(getDistributorReportFocus)).subscribe((focus) => {
        this.distributorReportFocus = focus;

        if (this.distributorReportFocus) {
          this.store.dispatch(
            fetchDistributorReport({
              focus: this.distributorReportFocus
            })
          );
        }
      })
    );
  }

  hideMainMenuFn() {
    this.hideMainMenu = this.router.url.includes('zelo/');
  }

  ngOnDestroy(): void {
    if (this.ticker) clearInterval(this.ticker);
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.store.dispatch(Logout());
  }

  tock(ticks: number) {
    const focus = this.distributorReportFocus;

    if (focus && this.router.url.includes('/zelo')) {
      const drModulus = Array.isArray(focus) ? 60 : 5;

      if (ticks % drModulus === 0 && !this.organization.e2e) {
        this.store.dispatch(
          fetchDistributorReport({
            focus: this.distributorReportFocus
          })
        );
      }
    }
  }
}
