import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  ViewChild,
  OnInit
} from '@angular/core';
import { User } from 'src/types';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { ENV_KEY } from 'src/app/auth/user.util';
import { SettingsService } from 'src/app/adminPortal/profile/services/settings.service';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { IconComponent } from '../../../../shared/components/icon.component';
import { PrivacyConsentComponent } from '../../../../shared/components/privacy-consent/privacy-consent.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
  selector: 'zlo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    IconComponent,
    RouterLink,
    PrivacyConsentComponent,
    NgFor,
    RouterLinkActive,
    NgClickOutsideDirective,
    TranslatePipe
  ]
})
export class HeaderComponent implements OnInit {
  @Input() user: User;

  @Output()
  logout = new EventEmitter();

  @ViewChild('#open-global-dropdown') dropdown: ElementRef;

  menuOpen: boolean;
  dbHostname: string;
  v2 = false;
  departmentNameToShow: string;

  appNavigationItems = [
    {
      translationKey: 'menu.zelos',
      path: '/zelo',
      id: 'all-messages'
    },
    {
      translationKey: 'menu.dashboard',
      path: '/dashboard',
      id: 'main-dashboard'
    },
    {
      translationKey: 'menu.recipients',
      path: '/employee',
      id: 'recipients'
    }
  ];

  dropdownOptionsAdmin: Array<{
    translationKey: string;
    path: string;
    action: string;
    requireAdmin: boolean;
    id: string;
  }> = [
    {
      translationKey: 'menu.profile',
      path: '/settings/profile',
      action: '',
      requireAdmin: false,
      id: 'my-profile'
    },
    {
      translationKey: 'menu.organization',
      path: '/settings/organization',
      action: '',
      requireAdmin: true,
      id: 'organization'
    },
    {
      translationKey: 'menu.attributes',
      path: '/settings/attributes',
      action: '',
      requireAdmin: true,
      id: 'attributes'
    },
    {
      translationKey: 'menu.admins',
      path: '/settings/admins',
      action: '',
      requireAdmin: true,
      id: 'admins'
    },
    {
      translationKey: 'menu.integrations',
      path: '/settings/integrations',
      action: '',
      requireAdmin: true,
      id: 'integrations'
    },
    {
      translationKey: 'menu.email',
      path: '/settings/domains',
      action: '',
      requireAdmin: true,
      id: 'email-settings'
    },
    {
      translationKey: 'menu.help',
      path: '',
      action: 'openHelpLink',
      requireAdmin: false,
      id: 'help-link'
    },
    {
      translationKey: 'menu.billing',
      path: null,
      action: 'billing',
      requireAdmin: true,
      id: 'billing'
    },
    {
      translationKey: 'settings.logout',
      path: '',
      action: 'logout',
      requireAdmin: false,
      id: 'logout'
    }
  ];

  dropdownOptionsManager: Array<{
    translationKey: string;
    path: string;
    action: string;
    requireAdmin: boolean;
  }>;

  constructor(
    public translate: TranslateService,
    private _eref: ElementRef,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService
  ) {
    this.menuOpen = false;
    this.dbHostname = localStorage.getItem(ENV_KEY);
    this.dropdownOptionsManager = this.dropdownOptionsAdmin.filter(
      (obj) => obj.requireAdmin === false
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.v2 = params.muster;
    });

    if (this.user?.organization?.tier?.toLowerCase().includes('enterprise')) {
      this.dropdownOptionsAdmin = this.dropdownOptionsAdmin.filter(
        (obj) => obj.translationKey !== 'menu.billing'
      );
    }

    this.departmentNameToShow = this.user.departments.find(
      (dep) => dep.id === this.user.loginDepartment
    )?.name;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (
      !this._eref.nativeElement.contains(event.target) &&
      this.menuOpen === true
    ) {
      this.menuOpen = false;
    }
  }

  hideMenu() {
    this.menuOpen = false;
    this.cdr.detectChanges();
  }

  showMenu() {
    this.menuOpen = true;
    this.cdr.detectChanges();
  }

  @HostListener('document:keydown', ['$event'])
  onKeypress(event: KeyboardEvent) {
    if (event.code === 'Escape') this.hideMenu();
  }

  onFocus() {
    setTimeout(() => {
      const activeEl = document.activeElement.id;
      if (activeEl === 'open-global-dropdown') this.showMenu();
    }, 10);
  }

  onBlur() {
    setTimeout(() => {
      const activeEl = document.activeElement.id;

      if (
        activeEl !== 'open-global-dropdown' &&
        !this.dropdownOptionsAdmin.map((x) => x.id).includes(activeEl)
      ) {
        this.hideMenu();
      }
    }, 50);
  }

  actionHandler(action: string) {
    if (action === 'billing') {
      this.settingsService.openBillingPortalTab();
    }

    if (action === 'openHelpLink') {
      window.open(
        this.user.organization.name.toLowerCase().split(' ').includes('spama')
          ? 'https://help.zeloapp.com/en/articles/8811348-united-compliance-user-guide'
          : 'https://help.zeloapp.com',
        '_blank'
      );
    }

    if (action === 'logout') {
      this.logout.emit();
    }
  }
}
