import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthRedirectGuard } from './auth/services/auth-redirect.guard';
import { RegistrationPageComponent } from './auth/containers/registration-page.component';
import { SignupComponent } from './auth/containers/signup.component';
import { EndUserComponent } from './enduserPortal/end-user.component';
import { combineReducers, provideState } from '@ngrx/store';
import { reducers as endUserReducer } from './enduserPortal/reducers';
import { reducers as EmployeeReducer } from './adminPortal/employee/reducers';
import { provideEffects } from '@ngrx/effects';
import { EnduserEffects } from './enduserPortal/effects/enduser.effects';
import { ZeloEffects } from './adminPortal/zelo/effects/zelo.effects';
import { AdminComponent } from './adminPortal/core/containers/admin.component';
import { AuthGuard } from './auth/services/auth.guard';
import { AdminViewReducer } from './adminPortal/core/reducers/admin.reducer';
import { reducers as ZeloReducers } from './adminPortal/zelo/reducers';
import { ZeloNextEffects } from './adminPortal/zelo/effects/zelo.next.effects';
import { AdminEffects } from './adminPortal/core/effects/admin.effects';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { SettingsEffects } from './adminPortal/profile/effects/settings.effect';
import { EmployeeEffects } from './adminPortal/employee/effects/employee.effects';
import { OrganizationEffects } from './organization.effects';

export const appRoutes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthRedirectGuard],
    loadChildren: () => import('./auth/auth.routes').then((m) => m.authRoutes)
  },
  {
    path: 'register',
    component: AuthComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
      { path: 'free-trial', component: RegistrationPageComponent }
    ]
  },
  {
    path: 'signup',
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
      { path: ':zeloId', component: SignupComponent }
    ]
  },
  {
    component: EndUserComponent,
    path: 'response',
    loadChildren: () =>
      import('./enduserPortal/end-user.routes').then((m) => m.enduserRoutes),
    providers: [
      provideState({
        name: 'enduser',
        reducer: combineReducers(endUserReducer)
      }),
      provideState({ name: 'master', reducer: combineReducers(ZeloReducers) }),
      provideEffects([EnduserEffects, ZeloNextEffects])
    ]
  },
  {
    component: AdminComponent,
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./adminPortal/admin.routes').then((m) => m.adminRoutes),
    providers: [
      provideState({ name: 'adminView', reducer: AdminViewReducer }),
      provideState({ name: 'master', reducer: combineReducers(ZeloReducers) }),
      provideState({
        name: 'employee',
        reducer: combineReducers(EmployeeReducer)
      }),
      provideEffects([
        ZeloEffects,
        ZeloNextEffects,
        AdminEffects,
        SettingsEffects,
        EmployeeEffects,
        OrganizationEffects
      ]),
      {
        provide: MAT_CHIPS_DEFAULT_OPTIONS,
        useValue: {
          separatorKeyCodes: ['ENTER', 'COMMA']
        }
      }
    ]
  }
];
