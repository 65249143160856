import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as CryptoJS from 'crypto-js';
import { User } from '../../../types';
import { isTestUser } from '../../adminPortal/shared/util';
import { Subscription } from 'rxjs';
import Intercom from '@intercom/messenger-js-sdk';

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements OnDestroy {
  loggedInUser: User | null;
  subscription: Subscription = new Subscription();
  private isAnalyticsInitialized = false;

  constructor(private store: Store<fromAuth.State>) {
    this.subscription.add(
      this.store.pipe(select(fromAuth.getUser)).subscribe((user: User) => {
        if (user) {
          this.loggedInUser = user;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadIntercom() {
    if (this.loggedInUser.organization.e2e || this.isAnalyticsInitialized)
      return;
    this.isAnalyticsInitialized = true;
  }

  identifySegmentUser = (
    user: User = this.loggedInUser,
    options: { isSignupFlow: boolean } = {
      isSignupFlow: false
    }
  ) => {
    if (!user) return;
    if (isTestUser(user)) return;

    const created_at = new Date(user.createdAt).getTime() / 1000;

    Intercom({
      app_id: environment.intercomAppId,
      user_id: user.id,
      name: user.firstname + ' ' + user.lastname,
      email: user.email,
      isAdmin: user.roles?.isAdmin,
      isManager: user.roles?.isManager,
      created_at: created_at,
      language: user.organization?.language,
      department: user.departments[0]?.name,
      isSpamaUser: ['spama'].includes(user.organization.name.toLowerCase()),
      isProduction: environment.production,
      isInSingupFlow: options.isSignupFlow,
      company: {
        name: user.organization?.name,
        id: user.organization?.id,
        plan: user.organization?.tier,
        isProduction: environment.production
      },
      user_hash: CryptoJS.enc.Hex.stringify(
        CryptoJS.HmacSHA256(user.id, environment.intercomVerificationKey)
      )
    });
  };

  track = (event: string, properties: any = {}) => {
    if (!this.isAnalyticsInitialized) return;
    if (!this.loggedInUser) return;
    if (isTestUser(this.loggedInUser)) return;

    (<any>window).Intercom('trackEvent', event, properties);
  };

  trackAnonymous = (event: string, properties: any = {}) => {
    if (!this.isAnalyticsInitialized) return;
    (<any>window).analytics.track('trackEvent', event, properties);
  };
}
