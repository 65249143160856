import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as Auth from '../actions/auth.actions';
import * as fromAuth from '../reducers';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private store: Store<fromAuth.State>) {}

  canActivate(
    _?: ActivatedRouteSnapshot,
    __?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromAuth.getLoggedIn).pipe(
      map((loggedIn) => {
        if (!loggedIn && !localStorage.getItem('id_token')) {
          this.store.dispatch(Auth.LoginRedirect());
          return false;
        }
        return true;
      })
    );
  }
}
