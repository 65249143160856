import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TOKEN_KEY } from '../user.util';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { Logout } from '../actions/auth.actions';

let refreshingInProgress: boolean;
let accessTokenSubject = new BehaviorSubject<string>(null);
let accessTokenTimestamp = Date.now();

export const TokenInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const toastr = inject(ToastrService);
  const store = inject(Store);

  const add = (request: HttpRequest<any>, token: string): HttpRequest<any> => {
    const auth = { Authorization: `Bearer ${token}` };
    return token ? request.clone({ setHeaders: auth }) : request;
  };

  const sessionExpired = (): void => {
    refreshingInProgress = false;
    toastr.warning('Please log in again.', 'Session expired');
    return store.dispatch(Logout());
  };

  const accessToken = localStorage.getItem(TOKEN_KEY);

  // add access token header to outgoing request
  return next(add(req, accessToken)).pipe(
    tap((res: any) => {
      if (accessToken && res.status === 200) {
        // with ongoing successful user activity ...
        if (accessTokenTimestamp + 1000 * 60 * 4 < Date.now()) {
          // get a new Access Token every 4 minutes
          accessTokenTimestamp = Date.now();
          //console.log('pre-emptively fetching new access token');
          authService.accessToken().subscribe();
        }
      }
    }),
    catchError((err) => {
      if (err.status !== 401) throw err;

      // an error occurred - we'll handle HTTP 401 Unauthorized
      if ((err.error?.message || '').split('|')[0] === 'otp') {
        // one-time-password sent to user as part of login flow
        // avoid token-related code below; convert to HTTP 200 response
        // login$ effect will map response to AuthActions.Otp
        const status = err.error.message;
        return of(new HttpResponse({ body: { status }, status: 200 }));
      }

      if (err.error?.message === 'Expired token') {
        if (refreshingInProgress) {
          // wait for refreshed token, then re-do request
          return accessTokenSubject.pipe(
            filter((token) => token !== null),
            switchMap((token) => {
              // console.log('re-requesting ' + req.url);
              return next(add(req, token));
            })
          );
        }

        refreshingInProgress = true;
        //console.log('set refreshingInProgress true');
        accessTokenSubject.next(null);

        return authService.accessToken().pipe(
          switchMap((res) => {
            refreshingInProgress = false;
            //console.log('set refreshingInProgress false');
            accessTokenSubject.next(res.token);
            //console.log('re-requesting ' + req.url);
            return next(add(req, res.token));
          }),
          catchError((err) => {
            if (err.error?.message === 'Bad Refresh Token') {
              //console.log('token interceptor: bad refresh token');
              sessionExpired();
              return err;
            }

            if (err.error?.message === 'Missing authentication') {
              sessionExpired();
              return err;
            }

            //console.log('token interceptor: caught unexpected error');
            return next(req);
          })
        );
      }

      if (
        ['Bad Refresh Token', 'Missing authentication'].includes(
          err.error?.message
        )
      ) {
        sessionExpired();
        return err;
      }

      //console.log('token interceptor: caught unexpected error');
      return next(req);
    })
  );
};
