import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EnduserService } from '../services/enduser.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as EnduserActions from '../actions/enduser.actions';
import { EndUserSettings } from 'src/types';

@Injectable({ providedIn: 'root' })
export class EnduserEffects {
  constructor(
    private actions$: Actions,
    private enduserService: EnduserService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  loadResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.loadEnduserResponse),
      exhaustMap(({ responseId, sessionStart }) =>
        this.enduserService.getZeloResponse(responseId, sessionStart).pipe(
          map((response) =>
            EnduserActions.loadEnduserResponseSuccess({ response })
          ),
          catchError((error) =>
            of(EnduserActions.loadEnduserResponseFailure(error))
          )
        )
      )
    )
  );

  loadResponseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnduserActions.loadEnduserResponseSuccess),
        tap(({ response }) =>
          this.translate.use(response.organization.language || 'spama')
        )
      ),
    { dispatch: false }
  );

  sendResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.sendEnduserResponse),
      exhaustMap(({ zeloResponse, isCheckPoint, isTestAnswer }) => {
        const { id: responseId } = zeloResponse;
        if (isCheckPoint) {
          return this.enduserService
            .checkpointZeloResonse(zeloResponse.id, zeloResponse)
            .pipe(
              map(() =>
                EnduserActions.loadEnduserResponse({
                  responseId
                })
              ),
              catchError((error) =>
                of(EnduserActions.sendEnduserResponseFailure(error))
              )
            );
        } else if (isTestAnswer) {
          const isTest = true;
          return this.enduserService
            .postZeloResponse(zeloResponse, isTest)
            .pipe(
              map(() =>
                EnduserActions.loadEnduserResponse({
                  responseId
                })
              ),
              catchError((error) =>
                of(EnduserActions.sendEnduserResponseFailure(error))
              )
            );
        } else {
          return this.enduserService.postZeloResponse(zeloResponse).pipe(
            map((updatedResponse) =>
              EnduserActions.sendEnduserResponseSuccess({
                response: updatedResponse
              })
            ),
            catchError((error) =>
              of(EnduserActions.sendEnduserResponseFailure(error))
            )
          );
        }
      })
    )
  );

  retakeTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.retakeTest),
      exhaustMap(({ responseId }) =>
        this.enduserService.retakeTest(responseId).pipe(
          map(() => EnduserActions.loadEnduserResponse({ responseId })),
          catchError((error) =>
            of(EnduserActions.sendEnduserResponseFailure(error))
          )
        )
      )
    )
  );

  updateZeloResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.updateEnduserResponseStep),
      filter((payload: any) => payload.responseId),
      exhaustMap(({ responseId, step }) =>
        this.enduserService.updateZeloResponseStep(step, responseId).pipe(
          map(() => EnduserActions.updateEnduserResponseStepSuccess()),
          catchError((error) =>
            of(EnduserActions.updateEnduserResponseStepFailure(error))
          )
        )
      )
    )
  );

  updateZeloReponseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnduserActions.updateEnduserResponseStepSuccess)
      ),
    { dispatch: false }
  );

  enduserError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EnduserActions.loadEnduserResponseFailure,
          EnduserActions.sendEnduserResponseFailure,
          EnduserActions.updateEnduserResponseStepFailure
        ),
        tap(({ error }) => {
          if (
            error.message.includes(
              'You have reached the maximum number of tries for this test'
            )
          ) {
            this.toastr.show('', `${error.message || ''}`, {
              toastClass: 'zelo-toast zelo-toast-alert'
            });
          } else {
            this.toastr.show(
              'Try again and contact us if the error persists.',
              `Something happened there. It might be this: ${
                error.name || ''
              } (${error.statusText || ''}) (Enduser)`,
              {
                toastClass: 'zelo-toast zelo-toast-alert'
              }
            );
          }
        })
      ),
    { dispatch: false }
  );

  loadStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.loadEnduserStatistics),
      exhaustMap(({ userId }) =>
        this.enduserService.getZeloStatistics(userId).pipe(
          map((userStatistics) =>
            EnduserActions.loadEnduserStatisticsSuccess({ userStatistics })
          ),
          catchError((error) =>
            of(EnduserActions.loadEnduserStatisticsFailure(error))
          )
        )
      )
    )
  );

  loadStatisticsForTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.loadEnduserStatisticsForTeams),
      exhaustMap(({ tenantId, remoteId }) =>
        this.enduserService.lookupUserIdForTeams(tenantId, remoteId)
      ),
      exhaustMap((user) =>
        this.enduserService
          .getZeloStatistics(user?.id || '')
          .pipe(
            map((userStatistics) =>
              EnduserActions.loadEnduserStatisticsSuccess({ userStatistics })
            )
          )
      )
    )
  );

  loadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.loadEnduserSettings),
      exhaustMap(({ userId }) =>
        this.enduserService.getEndUserSettings(userId).pipe(
          map((endUserSettings) => {
            if (endUserSettings.preferredLanguage) {
              this.translate.use(endUserSettings.preferredLanguage);
            }
            return EnduserActions.loadEnduserSettingsSuccess({
              endUserSettings
            });
          }),
          catchError((error) =>
            of(EnduserActions.loadEnduserSettingsFailure(error))
          )
        )
      )
    )
  );

  sendEndUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.sendEndUserSettings),
      exhaustMap(({ userId, endUserSettings }) =>
        this.enduserService.postEndUserSettings(userId, endUserSettings).pipe(
          map((newEndUserSettings: EndUserSettings) => {
            if (newEndUserSettings.preferredLanguage) {
              this.translate.use(newEndUserSettings.preferredLanguage);
            }
            return EnduserActions.sendEnduserSettingsSuccess({
              endUserSettings: newEndUserSettings
            });
          }),
          catchError((error) =>
            of(EnduserActions.sendEndUserSettingsFailure(error))
          )
        )
      )
    )
  );

  loadAvailableChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnduserActions.loadAvailableChannels),
      exhaustMap(({ organizationId }) =>
        this.enduserService
          .getAvailableChannelsForOrganization(organizationId)
          .pipe(
            map((availableChannels) =>
              EnduserActions.loadAvailableChannelsSuccess({ availableChannels })
            ),
            catchError((error) =>
              of(EnduserActions.loadAvailableChannelsFailure(error))
            )
          )
      )
    )
  );
}
