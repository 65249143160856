import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AttrAudience, AttrAudienceKey, AttrZelo, Zelo } from 'src/types';

const AUDIENCE_URL = `${environment.api}/v1/audience`;

@Injectable({ providedIn: 'root' })
export class AudienceService {
  zelo: Zelo;
  numberOfRecipientsInAudience = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  getAudienceTemplates(key: AttrAudienceKey) {
    let params = new HttpParams();

    if (key.limit) params = params.append('limit', key.limit);
    if (key.name) params = params.append('name', key.name);

    if (key.tags && key.tags.length) {
      for (const tag in key.tags) {
        params = params.append(`tags[]`, tag);
      }
    }

    return this.http.get<AttrAudience[]>(`${AUDIENCE_URL}/v2`, { params });
  }

  saveAudienceTemplate(
    template: AttrAudience,
    deleteHeadRevision = false,
    deletePermanently = false
  ) {
    if (deleteHeadRevision) {
      template = { i18n: {}, tags: {}, ...template };
    }

    return this.http.post<any>(`${AUDIENCE_URL}/v2`, {
      template,
      deleteHeadRevision,
      deletePermanently
    });
  }

  saveAttrZelo(attrZelo: AttrZelo) {
    const zeloId = attrZelo.zeloId;

    return this.http.post<any>(`${AUDIENCE_URL}/v2/${zeloId}`, {
      attrZelo
    });
  }
}
